<template>
  <v-col sm="6" md="4" lg="2">
    <v-autocomplete
      :items="packages"
      item-text="title"
      item-value="value"
      :loading="isLoading"
      :search-input.sync="search"
      menu-props="closeOnContentClick"
      @change="search = ''"
      deletable-chips
      hide-no-data
      hide-selected
      name="package"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        v-if="removeble"
        slot="append"
        title="Remove filter"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { FETCH_PACKAGE_DICTIONARY } from "@/store/modules/account/actions";
import { mapGetters } from "vuex";

export default {
  name: "AccountPackageSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    removeble: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isLoading: false,
      //Filter values
      items: [],
      //Filter entered value
      search: "",
    };
  },
  computed: {
    ...mapGetters("account", ["packages"]),
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    fetch: function () {
      this.isLoading = true;
      this.$store
        .dispatch("account/" + FETCH_PACKAGE_DICTIONARY)
        .then(
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        )
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  created: function () {
    this.fetch();
  },
};
</script>
