<template>
  <v-form @submit.prevent="updateItem" ref="form">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between py-1"
      >
        <span v-if="user">Edit user</span>
        <span v-if="!user">Create user</span>
        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2 px-6">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular
            size="64"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col>
            <v-text-field
              name="userName"
              :label="attributeLabels.userName"
              v-model="userForm.userName"
              type="text"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  (v && v.length >= 3) || 'Should contains at least 3 symbols',
                (v) =>
                  (v && v.length <= 40) || 'Should contains maximum 40 symbols',
              ]"
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="['admin', 'viewer', 'operator']"
              :label="attributeLabels.role"
              v-model="userForm.role"
              :rules="[(v) => !!v || 'Required']"
              validate-on-blur
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field
          name="email"
          :label="attributeLabels.userEmail"
          v-model="userForm.userEmail"
          :rules="[
            (v) => !!v || 'Required',
            (v) =>
              /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
                v
              ) || 'E-mail must be valid',
          ]"
          validate-on-blur
          type="text"
        ></v-text-field>
        <v-autocomplete
          :clearable="true"
          :items="accounts"
          item-text="title"
          item-value="value"
          :loading="isAccountLoading"
          :search-input.sync="accountSearch"
          menu-props="closeOnContentClick"
          @change="accountSearch = ''"
          deletable-chips
          small-chips
          hide-no-data
          :rules="[(v) => !!v || 'Required']"
          validate-on-blur
          hide-selected
          name="account"
          :label="attributeLabels.accountId"
          v-model="userForm.accountId"
        ></v-autocomplete>
        <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
          <div v-for="(error, key) in errors" :key="key">
            {{ prepareErrorMessage(error, key) }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="primary" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import {
  EDIT_USER,
  FETCH_ACCOUNTS_DICTIONARY,
} from "@/store/modules/account/actions";

const defaultValues = {
  accountEmulationAllowed: 0,
  accountId: null,
  role: "operator",
  userEmail: null,
  userName: null,
};

export default {
  name: "UserForm",
  props: {
    user: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      userForm: this.user
        ? {
            accountEmulationAllowed: this.user.accountEmulationAllowed ? 1 : 0,
            accountId: this.user.accountId,
            role: this.user.role,
            userEmail: this.user.email,
            userName: this.user.name,
          }
        : _.merge({}, defaultValues),
      birthdayModal: false,
      attributeLabels: {
        accountEmulationAllowed: "Allow super access",
        accountId: "Account",
        role: "Role",
        userEmail: "User email",
        userName: "User name",
      },
      //Filter values status
      isAccountLoading: false,
      //Filter values
      accounts: [],
      //Filter entered value
      accountSearch: null,
      errors: {},
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { userForm } = this;
        this.$store
          .dispatch("account/" + EDIT_USER, {
            user: userForm,
            id: this.user ? this.user.id : null,
          })
          .then(
            (response) => {
              this.dataLoading = false;
              if (response.result) {
                this.userForm = defaultValues;
                this.$emit("submitted", response.data);
                this.errors = {};
              } else {
                this.errors = response.details;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    fetchAccounts: function () {
      this.isAccountLoading = true;
      this.$store
        .dispatch("account/" + FETCH_ACCOUNTS_DICTIONARY)
        .then(
          (payload) => {
            this.isAccountLoading = false;
            this.accounts = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchAccounts();
  },
};
</script>
