var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateItem.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary lighten-1 white--text justify-space-between py-1"},[_vm._v(" Create account "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2 px-6"},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.dataLoading,"justify-center":"","align-center":"","opacity":"0.15"}},[_c('v-progress-circular',{attrs:{"size":"64","indeterminate":"","color":"primary"}})],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"name":"accountName","label":_vm.attributeLabels.accountName,"type":"text","rules":[
              (v) => !!v || 'Required',
              (v) =>
                (v && v.length >= 3) || 'Should contains at least 3 symbols',
              (v) =>
                (v && v.length <= 40) || 'Should contains maximum 40 symbols',
            ],"validate-on-blur":""},model:{value:(_vm.accountForm.accountName),callback:function ($$v) {_vm.$set(_vm.accountForm, "accountName", $$v)},expression:"accountForm.accountName"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"userName","label":_vm.attributeLabels.userName,"type":"text","rules":[
              (v) => !!v || 'Required',
              (v) =>
                (v && v.length >= 3) || 'Should contains at least 3 symbols',
              (v) =>
                (v && v.length <= 40) || 'Should contains maximum 40 symbols',
            ],"validate-on-blur":""},model:{value:(_vm.accountForm.userName),callback:function ($$v) {_vm.$set(_vm.accountForm, "userName", $$v)},expression:"accountForm.userName"}})],1)],1),_c('v-text-field',{attrs:{"name":"email","label":_vm.attributeLabels.email,"rules":[
          (v) => !!v || 'Required',
          (v) =>
            /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
              v
            ) || 'E-mail must be valid',
        ],"validate-on-blur":"","type":"text"},model:{value:(_vm.accountForm.email),callback:function ($$v) {_vm.$set(_vm.accountForm, "email", $$v)},expression:"accountForm.email"}}),(_vm.hasErrors)?_c('v-alert',{attrs:{"type":"error","elevation":"0","outlined":""}},_vm._l((_vm.errors),function(error,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm.prepareErrorMessage(error, key))+" ")])}),0):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","text":""}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }