<template>
  <div>
    <v-sheet
        outlined
        class="mt-1"
        color="grey lighten-5"
        @mouseover="upHere = true"
        @mouseleave="upHere = false"
    >
      <v-row class="pa-3 pl-3 align-center">
        <v-col cols="2" class="pl-9">
          <v-icon x-small class="mr-2" :color="statusColor"
          >mdi-checkbox-blank-circle
          </v-icon
          >
          {{ user.name }}
        </v-col>
        <v-col cols="2" class="text--secondary text-center">{{
            user.role
          }}
        </v-col>
        <v-col cols="2">{{ user.email }}</v-col>
        <v-col cols="2">{{ user.createdAt }}</v-col>
        <v-col cols="4" class="text-end pr-4">
          <span v-if="user.lastLogin !== '-' && (!upHere || !canManageUsers)"
          >Last logged: {{ user.lastLogin }}</span
          >
          <v-tooltip bottom v-if="upHere && canManageUsers">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     x-small
                     icon
                     @click="$emit('editInfo')"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <p class="my-2">Edit user</p>
          </v-tooltip>
          <v-tooltip bottom v-if="upHere && canManageUsers">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     x-small
                     icon
                     class="ml-3"
                     @click="$emit('deleteItem')"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <p class="my-2">Delete user</p>
          </v-tooltip>
          <v-tooltip bottom v-if="upHere && canManageUsers">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     x-small
                     icon
                     class="ml-3"
                     @click="$emit('resetPassword')"
              >
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <p class="my-2">Reset password</p>
          </v-tooltip>
          <v-tooltip bottom v-if="upHere && canManageUsers">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     x-small
                     icon
                     class="ml-3"
                     :color="emulatorColor"
                     @click="$emit('superAccess')"
              >
                <v-icon>mdi-account-wrench-outline</v-icon>
              </v-btn>
            </template>
            <p class="my-2">Super user access</p>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import acl from "@/mixin/acl";
import {RESOURCE_USERS_MANAGEMENT} from "@/store/modules/admin/getters";

export default {
  name: "UserItem",
  mixins: [acl],
  props: {
    user: Object,
  },
  data() {
    return {
      upHere: false,
    };
  },
  computed: {
    canManageUsers: function () {
      return this.hasAccess([RESOURCE_USERS_MANAGEMENT]);
    },
    statusColor: function () {
      let color = "grey lighten-1";
      if (this.user.lastLogin !== "-") {
        color = this.user.isActive ? "green darken-1" : "red darken-4";
      }
      return color;
    },
    emulatorColor: function () {
      return this.user.accountEmulationAllowed
          ? "green darken-1"
          : "gray darken-1";
    },
  },
};
</script>

<style lang="scss">
.col {
  padding: 5px;
}
</style>
